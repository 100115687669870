<template>
  <div class="left-bar-container">
    <ul class="left-bar">
      <li :class="{ 'left-bar-item': true, isActive: +steps === 1 }" style="height: 100px">
        <div class="bar-title">
          <span class="title-number">1</span>
          <span class="title-text">创建账号</span>
          <div class="bar-line"></div>
        </div>
        <ul class="sub-bar">
          <li class="sub-bar-item">
            <span>分配创建下级账号</span>
          </li>
        </ul>
      </li>
      <li :class="{ 'left-bar-item': true, isActive: +steps === 2 }" style="height: 100px">
        <div class="bar-title">
          <span class="title-number">2</span>
          <span class="title-text">账号定制</span>
          <div class="bar-line"></div>
        </div>
        <ul class="sub-bar">
          <li class="sub-bar-item">
            <span>账号授权合作模式以及查看报表权限</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      steps: 3
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.steps = val && val.query && val.query.steps
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.left-bar-container {
  width: 250px;
  min-width: 180px;
  padding: 40px 0 0 40px;
  background: #fff;
  border-radius: 10px;
  margin-right: 10px;
  .left-bar {
    padding: 0;
    margin: 0;
    .left-bar-item {
      cursor: pointer;
      position: relative;
      .bar-title {
        display: flex;
        height: 25px;
        align-items: center;
        margin-bottom: 26px;
        .title-number {
          display: inline-block;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          background: #e6e6e6;
          border-radius: 3px;
          font-size: 16px;
          font-weight: 600;
          z-index: 10;
        }
        .title-text {
          font-size: 16px;
          color: #333333;
          font-weight: 600;
        }
      }
      .bar-line {
        height: 100%;
        border-left: 2px solid #e6e6e6;
        position: absolute;
        top: 5px;
        left: 9px;
      }
      ul.sub-bar {
        padding: 0;
        margin: 0;
        color: #b3b3b3;
        li.sub-bar-item {
          padding: 0 30px;
          height: 16px;
          line-height: 16px;
          margin-bottom: 18px;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .left-bar-item.isActive {
      .bar-title {
        .title-number {
          background: @primary-color;
          color: #fff;
        }
      }
      .sub-bar {
        .sub-bar-item {
          color: #333333;
        }
      }
    }
  }
}
</style>
